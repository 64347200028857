
export enum PageIdentity {
    Home,
    SourceSelection,
    Era,
    ToolSelection,
    CharacterType,
    Species,
    SpeciesDetails,
    CustomSpeciesDetails,
    KobaliExtraSpeciesDetails,
    BorgSpeciesExtraDetails,
    LiberatedBorgSpeciesExtraDetails,
    CyberneticallyEnhancedSpeciesExtraDetails,
    Environment,
    EnvironmentDetails,
    Upbringing,
    UpbringingDetails,
    StarfleetAcademy,
    StarfleetAcademyDetails,
    Career,
    CadetCareer,
    CadetSeniority,
    ChildCareer,
    ChildEducationPage,
    ChildEducationDetailsPage,
    CareerDetails,
    CareerEvent1,
    CareerEvent1Details,
    CareerEvent2,
    CareerEvent2Details,
    AttributesAndDisciplines,
    BorgImplants,
    ExtraFocus,
    Finish,

    CustomSpaceframe,
    MissionProfileTalentSelection,
    MissionProfileSelection,
    MissionPodSelection,
    SimpleStarship,
    SmallCraftStats,
    SpaceframeOption,
    SpaceframeSelection,
    StarshipRefits,
    StarshipToolSelection,
    StarshipTypeSelection,
    StarshipTalentSelection,
    StarshipWeaponsSelection,
    ExpandedMunitionsWeaponsSelection,
    FinalStarshipDetails,
    SupportingCharacter,
    TalentsOverview,

    SystemGeneration,
    SectorDetails,
    StarSystemDetails,

    CreditsPage,
    ViewSheet,

    GamemasterTrackerPage,
    XmlViewer,

    ModificationTypeSelection,
    ReputationChange,
    Promotion,
    NormalMilestone,
    ModificationCompletePage,

    NpcConfiguration,
    TokenCreationPage
}
