
export enum NoseType {
    StraightBasic,
    Convex,
    SmallBulb,
    Broad,
    LongNarrow,
    HighBridge,
    Bulbous,
    SmallFlat,
    SmallUpTurned,
    Hawk,
    BroadFlat
}