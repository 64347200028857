export enum BodyType {

    AverageMale,
    Body2,
    Body3,
    Body4,
    Body5,
    AverageFemale,
    Body7,
    Body8,
}