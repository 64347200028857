// The Spaceframe identifiers that end in "_UP" are the redefined spaceframes from
// the Utopia Planitia book. In that book, several of the spaceframes have different
// stats than are shown in other books (Core, Command).

export enum Spaceframe {
    Akira,
    Akira_UP,
    Ambassador,
    Ambassador_UP,
    Archer,
    Centaur,
    Centaur_UP,
    Constellation,
    Constellation_UP,
    Constitution,
    Constitution_UP,
    Daedalus,
    Daedalus_UP,
    Defiant,
    Defiant_UP,
    Excelsior,
    Excelsior_UP,
    Galaxy,
    Hermes,
    Intrepid,
    Intrepid_UP,
    Luna,
    Luna_UP,
    Miranda,
    Miranda_UP,
    Nebula,
    Nebula_UP,
    NewOrleans,
    Norway,
    Norway_UP,
    Nova,
    NX,
    NX_UP,
    Oberth,
    Oberth_UP,
    Olympic,
    Olympic_UP,
    Saber,
    Sovereign,
    Sovereign_UP,
    Steamrunner,
    Steamrunner_UP,
    Sydney,

    // Discovery
    Walker,
    Shepard,
    Magee,
    Cardenas,
    Hoover,
    Malachowski,
    Engle,
    Nimitz,
    Crossfield,
    Hiawatha,


    // Discovery: Section 31
    StealthShip,
    Nimrod,
    Shiva,

    // Utopia Planitia
    JClassYClass,
    Delta,
    IntrepidType,
    Antares,
    Soyuz,
    Cheyenne,
    Springfield,
    RavenType,
    Niagara,
    Challenger,
    Freedom,
    Prometheus,
    Vesta,
    Ross,
    Inquiry,
    Reliant,
    Sutherland,
    Gagarin,
    Odyssey,
    Pathfinder,

    D5,
    Raptor,
    VonTalk,
    KToch,
    TuYuQ,
    D7,
    Brel,
    PachNom,
    QoToch,
    IwChaPar,
    D12,
    KlingonCivilianTransport,
    KVort,
    ParTok,
    Toron,
    VorCha,
    NeghVar,

    // Lower Decks
    California,
    Osler,
    Obena,
    Parliament,
    TKalat,
    Ganashia,

    // Discovery
    DiscoBirdOfPrey,
    Qugh,
    Daspu,
    Qoj,
    Batlh,
    Chargh,
    NaQjej,
    Elth,
    BortasBir,
    Sech,
    SarcophagusShip,
}