
export enum HeadType {
    AverageAngular,
    SquareJawed,
    RoundedNarrow,
    RoundedAverage,
    SofterNarrow,
    PointedDelicate,
    Rectangular,
    Elfin,
    PillShaped,
    RoundedHeavy
}